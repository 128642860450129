import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { IconButton } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import ReorderModal from "../../../../components/modals/ReorderModal";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import TransferDataModal from "../../../../components/modals/TransferDataModal";
import * as Filesaver from "file-saver";
const XLSX = require("xlsx");
class SponsorsExhibitors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reorderOpen: false,
      transferOpen: false,
      sponsorsForExport: [],
      countries: [],
      sponsorTypes: [],
      sponsorCategories: [],
      permission: "sponsor",
      scopeCondition: false,
      loaded: false,
    };
  }

  buttons = {};

  tools = {
    reorder: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Reorder"}>
          <IconButton onClick={() => this.setState({ reorderOpen: true })}>
            <ReorderIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
    transfer: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Transfer"}>
          <IconButton onClick={() => this.setState({ transferOpen: true })}>
            <MoveUpIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
    swapcardExport: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Swapcard Export"}>
          <IconButton onClick={() => this.swapcardExport()}>
            <StrikethroughSIcon />
          </IconButton>
        </Tooltip>
      ) : null
    },
  };

  formConfig = {
    rows: [
      [
        /*{
                    label: 'Template Sponsors',
                    field: 'Template Sponsors',
                    // field: 'template_sponsors',
                    datatype: 'template'
                },*/
        {
          label: "Published",
          field: "published",
          datatype: data_types.boolean,
        },
      ],
      [
        {
          label: "Company Name",
          field: "name",
          datatype: data_types.string,
        },
        {
          label: "Company Email",
          field: "email",
          datatype: data_types.string,
        },
        {
          label: "Company Phone",
          field: "phone",
          datatype: data_types.string,
        },
      ],
      [
        {
          field: "logo",
          label: "Colored logo",
          datatype: data_types.file,
          requirements: { width: 300, height: 100 },
        },
        {
          field: "logo_white",
          label: "White logo",
          datatype: data_types.file,
          requirements: { width: 300, height: 100 },
        },
        {
          field: "country",
          label: "Country",
          datatype: data_types.reference,
          reference: "countries",
        },
      ],
      [
        {
          label: "Booth number(s)",
          field: "booth_numbers",
          multiple: true,
          datatype: data_types.number,
        },
      ],
      [
        {
          label: "Sponsorship Type(s)",
          field: "types",
          multiple: true,
          datatype: data_types.reference,
          reference: "sponsor_types",
        },
        {
          label: "Sponsorship Category",
          field: "category_id",
          datatype: data_types.reference,
          reference: "sponsor_categories",
        },
      ],
      [
        "website",
        {
          label: "Contact person",
          field: "contact_name",
          datatype: data_types.string,
        },
        {
          label: "Contact email",
          field: "contact_email",
          datatype: data_types.string,
        },
      ],
      [
        {
          label: "Description",
          field: "description",
          datatype: data_types.string,
          multiline: true,
        },
      ],
    ],
  };

  gridProps = {
    fields: [
      "name",
      "booth_numbers",
      "types",
      "category_id",
      "published",
      "ordering",
      "created_at",
    ],
  };

  renderTransferDataModal = () => {
    return (
      <TransferDataModal
        open={this.state.transferOpen}
        onClose={() => this.setState({ transferOpen: false })}
        table={"sponsors"}
      />
    );
  };

  loadSponsorsForExport = async () => {
    await global.UF.dataProvider.get(`sponsors`, {event_id: global.UF.event_id}, (sponsors) => {
      this.setState({sponsorsForExport: sponsors});
    });
  }

  loadCountries = async () => {
    await global.UF.dataProvider.get(`countries`, {}, (countries) => {
      this.setState({countries: countries});
    });
  }

  loadSponsorTypes = async () => {
    await global.UF.dataProvider.get(`sponsor_types`, {event_id: global.UF.event_id}, (sponsorTypes) => {
      this.setState({sponsorTypes: sponsorTypes});
    });
  }

  loadSponsorCategories = async () => {
    await global.UF.dataProvider.get(`sponsor_categories`, {event_id: global.UF.event_id}, (sponsorCategories) => {
      this.setState({sponsorCategories: sponsorCategories});
    });
  }

  swapcardExport = async () => {
    this.loadSponsorsForExport();
    this.loadCountries();
    this.loadSponsorTypes();
    this.loadSponsorCategories();
    setTimeout(() => {
      if (this.state.sponsorsForExport.length && this.state.countries.length && this.state.sponsorTypes.length && this.state.sponsorCategories.length) {
        let sponsors = [];
        this.state.sponsorsForExport.forEach((sponsor) => {
          const countryForExport = this.state.countries.filter((country) => {
            return country.code === sponsor.country;
          });
          const country = countryForExport.length ? countryForExport[0].name : null;
          const boothNumbers = JSON.parse(sponsor.booth_numbers);
          const locationName = boothNumbers !== null ? (boothNumbers.length ?
          (boothNumbers.length > 1 ? `Booths ${boothNumbers.join(', ')}` : `Booth ${boothNumbers[0]}`) 
          : null) : null;
          const typesForExport = JSON.parse(sponsor.types);
          let types = [];
          typesForExport.forEach((type) => {
            const filtered = this.state.sponsorTypes.filter((sponsorType) => {
              return sponsorType.id === type;
            });
            if (filtered.length) {
              const name = filtered[0].name === 'Exhibitors' ? 'Exhibitor' : filtered[0].name;
              types.push(name)
            }
          });
          const category = this.state.sponsorCategories.filter((sponsorCategory) => {
            return sponsorCategory.id === sponsor.category_id;
          });
          const categoryName = category.length ? category[0].name : null;
          let sponsorForExport = {
            name: sponsor.name,
            description: sponsor.description,
            groupId: null,
            groupName: "Exhibitors",
            logoUrl: `https://bo-crm-live-v2.ultimatefintech.com/api/files/${sponsor.logo}`,
            websiteUrl: sponsor.website,
            linkedin: null,
            twitter: null,
            instagram: null,
            facebook: null,
            email: sponsor.email,
            boothNumbers: locationName,
            locationCategory: null,
            mobile: null,
            landline: sponsor.phone,
            city: null,
            country: country,
            scanBadge: "Yes",
            addMembers: "Yes",
            externalId: sponsor.id,
            type: null,
            sponsorTypes: types.join('; '),
            category: categoryName,
          };
          sponsors.push(sponsorForExport);
        });
        this.createExcel(sponsors);
      }
    }, 1000);

  };

  createExcel = async (sponsors) => {
    await fetch('/api/personal/asia-24-exhibitors-export.xlsx')
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const workbook = XLSX.read(new Uint8Array(buffer, {type: 'array'}));
        const sheet = workbook.Sheets['Exhibitors'];

        sponsors.forEach((sponsor) => {
          const importRows = [
              // ["Name", "Description", "Group ID", "Group name", "Logo URL", "Website URL", "LinkedIn", "X (Twitter)", "Instagram", "Facebook", "Email", "Location name", "Location category", "Mobile phone", "Landline phone", "City", "Country", "Scan badge", "Add members", "External id", "Custom fields.en.RmllbGREZWZpbml0aW9uXzY0ODE5OQ==", "Custom fields.en.RmllbGREZWZpbml0aW9uXzY0ODIwMQ=="],
            [sponsor.name, sponsor.description, sponsor.groupId, sponsor.groupName, sponsor.logoUrl, sponsor.websiteUrl, sponsor.linkedin, sponsor.twitter, sponsor.instagram, sponsor.facebook,sponsor.email, sponsor.boothNumbers, sponsor.locationCategory, sponsor.mobile, sponsor.landline, sponsor.city, sponsor.country, sponsor.scanBadge, sponsor.addMembers, sponsor.externalId, sponsor.type, sponsor.sponsorTypes, sponsor.category]
          ];
          XLSX.utils.sheet_add_aoa(sheet, importRows, {origin: -1});
        });

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const excelBuffer = XLSX.write(workbook, { "bookType": "xlsx", "type": "array" });
        const file = new Blob([excelBuffer], {"type": fileType});
        
        Filesaver.saveAs(file, `swapcard-sponsors-export` + fileExtension);
      })
      .catch(err => console.error(err));
  }

  componentDidMount() {
    if (global.UF.EVENT_SETTINGS?.EVENT === "FM") {
      global.UF.dataProvider.datastructure[`sponsors`].fieldsArr.find(
        (field) => field.Field === "email"
      ).validators = [];
      global.UF.dataProvider.datastructure[`sponsors`].ignoreValidation.push(
        "email"
      );
    }
    this.findCurrentPermission();
  }

  fetchData = () => {};

  getFetchData = (fetchData) => {
    this.fetchData = fetchData;
  };

  renderReorderingModal = () => {
    return (
      <ReorderModal
        open={this.state.reorderOpen}
        onClose={() =>
          this.setState({ reorderOpen: false }, () => this.fetchData())
        }
        table={"sponsors"}
        onClick={this.fetchData}
      />
    );
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.event_id = global.UF.event_id;
    global.UF.makeRequest(
      `POST`,
      `/api/get-ordering`,
      { table: "sponsors" },
      true,
      (data) => {
        dataItem.ordering = data.body.ordering;
        if (callback) {
          callback();
        }
      },
      (error) => {
        console.error(error);
      },
      true,
      false
    );
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  render() {
    return (
      <Fragment>
        {this.state.reorderOpen && this.renderReorderingModal()}
        {this.state.transferOpen && this.renderTransferDataModal()}
        <UF_Content
          table="sponsors"
          id={this.props.event_id}
          title="Sponsor Exhibitors"
          permission={"sponsor"}
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          export={this.state.scopeCondition}
          tools={this.tools}
          formConfig={this.formConfig}
          fetchData={this.getFetchData}
          gridProps={this.gridProps}
          beforeAddNew={this.handleBeforeAddNew}
        />
      </Fragment>
    );
  }
}

export default SponsorsExhibitors;
